<template>
    <div class="width-full h-screen fixed top-0 right-0 flex items-center justify-center">
        <Card class="flex p-4 flex-col" style="width: 500px">
            <p class="w-full font-extrabold text-xs text-jetOpacity">+ Add New Group</p>

            <GroupToolTip :isGroupToolTip="isGroupToolTip"
                @on-enter="isGroupToolTip = true"
                @on-leave="isGroupToolTip = false"
            />

            <div class="col-span-3">
                <c-text
                    placeholder="--Enter--"
                    variant="w-full"
                    v-model="name"
                />
            </div>

            <div class="w-full my-4 flex items-end justify-end">
                <button
                    class="w-auto h-auto text-flame text-xs font-semibold uppercase border-none outline-none"
                    :disabled="disableAddBtn"
                    @click="submitGroup"
                >
                    {{ disableAddBtn ? 'Update...' : 'Update' }}
                </button>

                <button
                    background-color="none"
                    class="w-auto h-auto ml-4 text-jetOpacity text-xs font-semibold uppercase border-none outline-none"
                    :disabled="disableAddBtn"
                    @click="closeModal"
                >
                    Cancel
                </button>
            </div>
        </Card>    
    </div>
</template>
<script>
export default {
    name: "EditGroup",
    components: {
        CText: () => import("@scelloo/cloudenly-ui/src/components/text"),
        Card: () => import("../../Card"),
        GroupToolTip: () => import("./GroupToolTip")
    },
    props: {
        disableAddBtn: Boolean,
        groupName: String
    },
    data() {
        return {
            isGroupToolTip: false,
            isSubmitting: false,
            name: this.groupName
        }
    },
    methods: {
        closeModal(){
            this.$emit("cancel");
        },
        submitGroup(){
            this.$emit("edit-group", this.name)
        }
    },
};
</script>
<style scoped>
.width-full{
    width: 38.5rem;
    z-index: 9999 !important;
    background: rgba(0, 0, 0, .5) !important;
}
</style>
